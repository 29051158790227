import React, { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OverviewModel } from '@type/index';
import { ThemeProps } from '@theme/ThemeProps.model';
import { generateLink } from '@utils/canonicalUrl.utils';
import {
  Helmet,
  SingleSection,
  isOverviewPage,
  injectSheet,
  setStationSlug
} from '@imports';

import styles from './Overview.styles';
import { BRAND_TITLE } from '@constants/constants';

interface Props {
  data: OverviewModel;
  landingPage: boolean;
}

type OverviewProps = ThemeProps & Props;

const Overview: React.FC<OverviewProps> = ({
  data,
  classes
}): ReactElement | null => {
  const dispatch = useDispatch();
  const fallbackImage = useSelector(
    (state: any) => state.landing.fallbackImage
  );
  const showLabelOnArticleCards = useSelector(
    (state: any) => state.landing.showLabelOnArticleCards
  );
  const homeSlug = useSelector((state: any) => state.landing.homeoverview);
  const isRadioPlaying = useSelector(
    (state: any) => state.radioplayer.player.isPlaying
  );

  const { hasLoaded } = useSelector((state: any) => state.radioplayer.content);

  const changeStationSlug = () => {
    const stationSlug = data?.fields?.stationSlug;
    !isRadioPlaying && stationSlug && dispatch(setStationSlug(stationSlug));
  };

  const isLanding = () => {
    if (process.browser) {
      return window.location.pathname === '/';
    }
    return false;
  };

  useEffect(() => {
    dispatch(isOverviewPage(true));
    hasLoaded && changeStationSlug();
    return function cleanup() {
      dispatch(isOverviewPage(false));
    };
  }, []);

  useEffect(() => {
    if (hasLoaded) {
      changeStationSlug();
    }
  }, [hasLoaded]);

  if (!data?.fields?.sections?.length) return null;
  const { title, slug, sections } = data.fields;
  const helmetTitle = `${title} | ${BRAND_TITLE}`;
  const generateLinkSlug = homeSlug === slug ? '' : slug;

  return (
    <div data-testid="overview" className={classes.overview}>
      <Helmet title={helmetTitle} link={generateLink(generateLinkSlug)} />

      {sections.map((section, index) => (
        <SingleSection
          section={section}
          index={index}
          homeSlug={homeSlug}
          key={index}
          fallbackImage={fallbackImage}
          showLabelOnArticleCards={showLabelOnArticleCards}
          landingPage={isLanding()}
        />
      ))}
    </div>
  );
};

export default injectSheet(styles)(Overview);
